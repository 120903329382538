import CustomButton from "components/button";
import { CustomerInterface, Feature } from "interfaces";
import { useNavigate, useParams } from "react-router-dom";
import { Checkbox, Header } from "semantic-ui-react";
import { useEffect, useState } from 'react';
import { api, ui } from "services";
import { TextInput } from "components/text-input";
import { useTranslation } from "react-i18next";

const generalSetting: any =
{
    [Feature.GAMEPLAY_EDIT_LAND]: "Edit land",
    [Feature.GAMEPLAY_CREATE_BLUE_PRINT]: "Create Blueprint",
    [Feature.GAMEPLAY_CONVERT_NFT]: "Convert NFT",
    [Feature.GAMEPLAY_CONVERT_FT]: "Convert Items",
    [Feature.GAMEPLAY_HATCH_EGG]: "Hatch",
    [Feature.GAMEPLAY_BREED]: "Breed",
    [Feature.GAMEPLAY_FEED_FOOD]: "Feed",
    [Feature.GAMEPLAY_EVOLVE]: "Evolve up",
    [Feature.GAMEPLAY_EXTRACT_EVO]: "Extract Evolution potions",
    [Feature.GAMEPLAY_EDIT_SKILL]: "Edit skill",
    [Feature.GAMEPLAY_UPGRADE_PLOT]: "Upgrade plot",
    [Feature.GAMEPLAY_TRAINING_TROOPS]: "Train troops",
    [Feature.LEGEND_ISLA_LOGIN]: "Login legend isla page",
    [Feature.SUPPORT_LOGIN]: "Login event page",
    [Feature.CLICK2EARN_FUSION]: "Fusion",
    [Feature.CLICK2EARN_MUTATION]: "Mutate gen"
}

/* eslint-disable react-hooks/exhaustive-deps */
export default function ScholarHome() {
    const { id } = useParams();
    const nav = useNavigate();
    const [settings, setSettings] = useState([]);
    const [scholarData, setScholarData] = useState(null);
    const [expectAmount, setExpectAmount] = useState({
        "expectEarningMstr": 0,
        "expectEarningMag": 0,
        "expectEarningElixir": 0,
        "expectEarningMutategen": 0,
    })
    const [isEdit, setIsEdit] = useState(false);
    const { t } = useTranslation();

    const getScholarDetail = async () => {
        try {
            let data = await api.post("/scholar/get-customer-info", {});
            console.log({data})
            setScholarData(data);
            const permissions = data[CustomerInterface.scholar_permissions];
            let permissionsData = Object.keys(generalSetting).map((e: any) => {
                return {
                    key: e,
                    label: generalSetting[e],
                    value: permissions[e] || false,
                    disabled: [Feature.GAMEPLAY_CONVERT_NFT, Feature.GAMEPLAY_CONVERT_FT].includes(Number(e))
                }
            })
            const expect_earning = data[CustomerInterface.expect_earning];
            expect_earning && setExpectAmount(expect_earning);
            setSettings(permissionsData)
        } catch (err) {
            ui.alertFailed("Fetch data failed !", "Please try again later.");
        }
    }

    useEffect(() => {
        getScholarDetail();
    }, [id])

    return (
        <div className="main-layout w-full pt-10 px-14 text-white" id="resize-content">
            <div className="w-full" id="resize-content">
                <Header className="text-left border-section"
                >
                    <div className="pb-10 text-white text-3xl font-semibold flex gap-4 cursor-pointer">
                        <span>
                            Scholar account #{scholarData?.[CustomerInterface.id]}
                        </span>
                    </div>
                </Header>
                <div>
                    <div className="">
                        <div>
                            <div className="font-cookie-run text-xl mt-12">
                                Expect assets earning per day
                            </div>
                            <div className="w-full max-w-[600px] grid grid-cols-2 gap-x-4 mt-4">
                                <TextInput
                                    type="number"
                                    step={0.1}
                                    min={0}
                                    defaultValue={0}
                                    fieldName="Expect MSTR earning per day"
                                    value={expectAmount.expectEarningMstr}
                                    setValue={(value: any) => {
                                        setExpectAmount({
                                            ...expectAmount,
                                            expectEarningMstr: value,
                                        });

                                    }}
                                    disabled={!isEdit}
                                />
                                <TextInput
                                    type="number"
                                    step={0.1}
                                    min={0}
                                    defaultValue={0}
                                    fieldName="Expect MAG earning per day"
                                    value={expectAmount.expectEarningMag}
                                    setValue={(value: any) => {
                                        setExpectAmount({
                                            ...expectAmount,
                                            expectEarningMag: value,
                                        });
                                    }}
                                    disabled={!isEdit}
                                />
                                <TextInput
                                    type="number"
                                    step={0.1}
                                    min={0}
                                    defaultValue={0}
                                    fieldName="Expect Elixir earning per day"
                                    value={expectAmount.expectEarningElixir}
                                    setValue={(value: any) => {
                                        setExpectAmount({
                                            ...expectAmount,
                                            expectEarningElixir: value,
                                        });
                                    }}
                                    disabled={!isEdit}
                                />
                                <TextInput
                                    type="number"
                                    step={0.1}
                                    min={0}
                                    defaultValue={0}
                                    fieldName="Expect Mutate gen earning per day"
                                    value={expectAmount.expectEarningMutategen}
                                    setValue={(value: any) => {
                                        setExpectAmount({
                                            ...expectAmount,
                                            expectEarningMutategen: value,
                                        });
                                    }}
                                    disabled={!isEdit}
                                />
                            </div>
                        </div>

                        <div>
                            <div className="font-cookie-run text-xl mt-12">
                                Scholar sharing rate
                            </div>
                            <div className="flex gap-10">
                                <div className="mt-1 text-lg">
                                    MAG Sharing Rate: <span className="font-cookie-run">{scholarData?.[CustomerInterface.sharing_rate]?.magRate}%</span>
                                </div>
                                <div className="mt-1 text-lg">
                                    MSTR Sharing Rate: <span className="font-cookie-run">{scholarData?.[CustomerInterface.sharing_rate]?.mstrRate}%</span>
                                </div>
                            </div>
                            <div className="flex gap-10">
                                <div className="mt-1 text-lg">
                                    Elixir Sharing Rate: <span className="font-cookie-run">{scholarData?.[CustomerInterface.sharing_rate]?.elixirRate}%</span>
                                </div>
                                <div className="mt-1 text-lg">
                                    Mutate gen Sharing Rate: <span className="font-cookie-run">{scholarData?.[CustomerInterface.sharing_rate]?.mutategenRate}%</span>
                                </div>
                            </div>
                            <div className="mt-1 text-lg setting">
                                <Checkbox
                                    className={"cursor-disabled"}
                                    disabled={true}
                                    key={"Auto assign"}
                                    label={"Auto assign NFTs"}
                                    defaultChecked={scholarData?.[CustomerInterface.sharing_rate]?.autoAssignNft}
                                    value={scholarData?.[CustomerInterface.sharing_rate]?.autoAssignNft}
                                    onChange={(e, data) => {
                                        // data.checked
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="font-cookie-run text-xl mt-12">
                            Permissions
                        </div>

                        <div className="setting flex flex-wrap lg:grid lg:grid-cols-3 gap-8 mt-4 pl-[4px]">
                            {settings.map((setting, idx) => {
                                return <Checkbox
                                    className={`${setting.disabled && "cursor-disabled"}`}
                                    disabled={true}
                                    key={setting.label}
                                    label={setting.label}
                                    defaultChecked={setting.value}
                                    value={setting.value}
                                    onChange={(e, data) => {
                                        let newSettings = settings.map((e: any) => {
                                            if (e.key === setting.key) {
                                                return {
                                                    ...e,
                                                    value: data.checked,
                                                }
                                            }
                                            return e;
                                        })
                                        setSettings(newSettings);
                                    }}
                                />
                            })}
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}
