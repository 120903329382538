import { Loading } from "components";
import { BoxCard } from "components/box-card";
import { ArtButton } from "components/button";
import { MongenCard, MongenThumb } from "components/mongen-card";
import { PlotCard, PlotThumb } from "components/plot-card";
import { t } from "i18next";
import { BodyPart, MongenInterface, MongenRace, MongenState, NFTType, PlotInterface, PlotType, Rarity, ScholarItemStatus } from "interfaces";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setUserInfo } from "reducer/user.slice";
import { Header, Modal, Pagination, Select } from "semantic-ui-react";
import { api, ui } from "services";

/* eslint-disable react-hooks/exhaustive-deps */
export default function NFTDetail() {
  const [typeFilter, setTypeFilter] = useState(NFTType.Plot);
  const [rarityFilter, setRarityFilter] = useState(Rarity.All);
  let [statusFilter, setStatusFilter] = useState(ScholarItemStatus.All);

  const [items, setItems] = useState<any>({});
  const [nftPage, setNftPage] = useState(1);
  const [nftTotal, setNftTotal] = useState(0);
  const [loadingGetItem, setLoadingGetItem] = useState(false);

  const [cleanData, setCleanData] = useState(null);
  const nftLimit = 20;

  const { id } = useParams();
  let [viewMode, setViewMode] = useState<'all' | 'owner' | 'scholar' | 'unowned'>('all')

  const getItems = async () => {
    if (viewMode === 'unowned') {
      statusFilter = ScholarItemStatus.Waiting;
      viewMode = 'scholar'
    }
    try {
      setLoadingGetItem(true);
      const data = await api.getItems({
        nft_type: typeFilter,
        rarity: rarityFilter,
        limit: nftLimit,
        offset: (nftPage - 1) * nftLimit,
        status: statusFilter,
        scholar_id: Number(id),
        viewMode
      })
      setItems(data)
      setNftTotal(data.total ?? 0)
    } catch (err) {
    } finally {
      setLoadingGetItem(false);
    }
  }

  const unassignItem = async (identity: string, nftType: NFTType, nftId: number) => {
    try {
      await api.unassignNft(identity, nftType, nftId);
      await getItems()
      ui.alertSuccess(`Unassign NFT from Scholar ${identity} success`, "");
    } catch (err: any) {
      ui.alertFailed(t(err.message), "");
    }
  }

  const exchangeFn = async (mongen_ids: number[], plot_ids: number[], rarity: Rarity, scholar_id: number) => {
    try {
      await api.exchangeVault(mongen_ids, plot_ids, rarity, scholar_id);
      await getItems()
      ui.alertSuccess(`Exchange item success`, "");
    } catch (err: any) {
      ui.alertFailed(t(err.message), "");
    }
  }

  const takeOwnerRight = async (id: number, nftType: NFTType) => {
    try {
      await api.takeOwnerRight(id, nftType);
      await getItems()
      ui.alertSuccess(`Take owner right of item success`, "");
    } catch (err: any) {
      ui.alertFailed(t(err.message), "");
    }
  }

  const assignItem = async (identity: string, nftType: NFTType, nftId: number) => {
    try {
      await api.assignNft(identity, nftType, nftId);
      await getItems()
      ui.alertSuccess(`Assign NFT ${nftId} to Scholar ${identity} success`, "");
    } catch (err: any) {
      ui.alertFailed(t(err.message), "");
    }
  }

  const assignWaitingFn = async (identity: string, nftType: NFTType, nftId: number) => {
    try {
      await api.assignWaitingFn(identity, nftType, nftId);
      await getItems()
      ui.alertSuccess(`Assign NFT ${nftId} to Scholar ${identity} success`, "");
    } catch (err: any) {
      ui.alertFailed(t(err.message), "");
    }
  }

  useEffect(() => {
    setItems(null)
    getItems();
  }, [nftPage, typeFilter, rarityFilter, statusFilter, viewMode])

  useEffect(() => {
    setNftPage(1);
  }, [typeFilter, rarityFilter, statusFilter])

  return (
    <div className="main-layout w-full pt-10 px-14 text-white" id="resize-content">
      <div className="w-full" id="resize-content">
        <Header className="text-left border-section">
          <div className="pb-10 text-white text-3xl font-semibold">
            NFTs list
          </div>
        </Header>
        <div className="flex bg-[#242424] max-w-min rounded-xl">
          <div className={`cursor-pointer min-w-max p-3 border-[#3D3E40] border-r-2 flex gap-2 px-4 ${typeFilter === NFTType.Plot && "active-filter"}`} onClick={() => {
            setTypeFilter(NFTType.Plot)
          }}
          >
            <img src="/assets/images/items/plot-icon.svg" className="w-6 h-6 justify-center self-center" alt="icon" />
            <div className="justify-center self-center">
              Landplots
            </div>
          </div>
          <div className={`cursor-pointer min-w-max p-3 border-[#3D3E40] border-r-2 flex gap-2 px-4 ${typeFilter === NFTType.Mongen && "active-filter"}`} onClick={() => {
            setTypeFilter(NFTType.Mongen)
          }}
          >
            <img src="/assets/images/items/mongen-icon.svg" className="w-6 h-6 justify-center self-center" alt="icon" />
            <div className="justify-center self-center">
              Mongens
            </div>
          </div>
          <div className={`cursor-pointer min-w-max p-3 flex gap-2 px-4 ${typeFilter === NFTType.SoulCore && "active-filter"}`} onClick={() => {
            setTypeFilter(NFTType.SoulCore)
          }}
          >
            <img src="/assets/images/items/soulcore-icon.svg" className="w-6 h-6 justify-center self-center" alt="icon" />
            <div className="justify-center self-center">
              Soulcores
            </div>
          </div>
          <div className={`cursor-pointer min-w-max p-3 flex gap-2 px-4 ${typeFilter === NFTType.Box && "active-filter"}`} onClick={() => {
            setTypeFilter(NFTType.Box)
          }}
          >
            <img src="/assets/images/items/box-icon.svg" className="w-6 h-6 justify-center self-center" alt="icon" />
            <div className="justify-center self-center">
              Boxs
            </div>
          </div>
        </div>
        <div className="filter-container flex gap-4 flex-wrap mt-4 mb-12 nft-container">
          <Select defaultValue={'all'} options={[
            { key: `nft-all`, value: 'all', text: 'All' },
            { key: `nft-owner`, value: 'owner', text: 'Only your NFTs' },
            { key: `nft-scholar`, value: 'scholar', text: `Only scholar's NFTs` },
            { key: `nft-Unowned`, value: 'unowned', text: `Unowned` },
          ]}
            onChange={(_, value: any) => {
              setViewMode(value.value);
            }}
          />
          <Select defaultValue={Rarity.All} options={[
            { key: `rarity-${Rarity.All}`, value: Rarity.All, text: 'All Rarity' },
            { key: `rarity-${Rarity.Common}`, value: Rarity.Common, text: 'Common' },
            { key: `rarity-${Rarity.Uncommon}`, value: Rarity.Uncommon, text: 'Uncommon' },
            { key: `rarity-${Rarity.Rare}`, value: Rarity.Rare, text: 'Rare' },
            { key: `rarity-${Rarity.Epic}`, value: Rarity.Epic, text: 'Epic' },
            { key: `rarity-${Rarity.Legendary}`, value: Rarity.Legendary, text: 'Legendary' },
          ]}
            onChange={(_, value: any) => {
              setRarityFilter(value.value);
            }}
          />
          <Select defaultValue={ScholarItemStatus.All} options={[
            { key: `rarity-${ScholarItemStatus.All}`, value: ScholarItemStatus.All, text: 'All status' },
            { key: `rarity-${ScholarItemStatus.Available}`, value: ScholarItemStatus.Available, text: 'Available' },
            { key: `rarity-${ScholarItemStatus.OnSale}`, value: ScholarItemStatus.OnSale, text: 'On Sale' },
            { key: `rarity-${ScholarItemStatus.InUse}`, value: ScholarItemStatus.InUse, text: 'In Use' },
            { key: `rarity-${ScholarItemStatus.Lending}`, value: ScholarItemStatus.Lending, text: 'Lending' },
            { key: `rarity-${ScholarItemStatus.Banned}`, value: ScholarItemStatus.Banned, text: 'Banned' },
            // { key: `rarity-${ScholarItemStatus.UsedByScholar}`, value: ScholarItemStatus.UsedByScholar, text: 'In Use By Scholar' },
          ]}
            onChange={(_, value: any) => {
              setStatusFilter(value.value);
            }}
          />
        </div>
        {loadingGetItem && !items?.data ?
          <Loading className="min-h-[300px]" /> :
          <>
            <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
              {items?.data?.map((item: any, idx: number) => {
                if (item.type === "mongen") {
                  let dna = item[MongenInterface.dna];
                  let rarity = !item[MongenInterface.mongen_state] ? dna[1][0] : dna[BodyPart.Aura][0];
                  let nftType = !item[MongenInterface.mongen_state] ? NFTType.SoulCore : NFTType.Mongen;

                  return <MongenCard key={`mongen-card-${idx}`} mongenInfo={item} userInfo={item.customerInfo}
                    assignFn={async () => {
                      await assignItem(id, typeFilter, item[MongenInterface.id])
                    }}
                    assignWaitingFn={async () => {
                      await assignWaitingFn(id, typeFilter, item[MongenInterface.id])
                    }}
                    unassignFn={async () => {
                      await unassignItem(id, typeFilter, item[MongenInterface.id])
                    }}
                    exchangeFn={async () => {
                      await exchangeFn([item[MongenInterface.id]], [], rarity, Number(id))
                    }}
                    cleanFn={() => {
                      setCleanData({
                        type: typeFilter,
                        id: item[MongenInterface.id],
                        itemInfo: item
                      })
                    }}
                    getOwnerRight={() => {
                      ui.confirmAction('Confirm take owner right', <>
                        <div className="text-lg">You must pay
                          <span className="text-yellow font-bold text-xl"> {item[MongenInterface.scholar_vault] ?? "--"} MAG </span> to take owner right of this item.
                        </div>
                        <div className="flex gap-1 justify-center text-center text-lg my-4">
                          <div className="justify-center self-center">Are you sure want to continue</div>
                        </div>
                      </>, 'text', async () => {
                        await takeOwnerRight(item[MongenInterface.id], nftType)
                      })
                    }}
                  />
                } else if (item.type === "plot") {
                  return <PlotCard key={`plot-card-${idx}`} plotInfo={item} userInfo={item.customerInfo}
                    assignFn={async () => {
                      await assignItem(id, typeFilter, item[PlotInterface.id])
                    }}
                    unassignFn={async () => {
                      await unassignItem(id, typeFilter, item[PlotInterface.id])
                    }}
                    exchangeFn={async () => {
                      await exchangeFn([], [item[PlotInterface.id]], item[PlotInterface.rarity], Number(id))
                    }}
                    cleanFn={() => {
                      setCleanData({
                        type: typeFilter,
                        id: item[PlotInterface.id],
                        itemInfo: item
                      })
                    }}
                    assignWaitingFn={async () => {
                      await assignWaitingFn(id, typeFilter, item[PlotInterface.id])
                    }}
                    getOwnerRight={() => {
                      ui.confirmAction('Confirm take owner right', <>
                        <div className="text-lg">You must pay
                          <span className="text-yellow font-bold text-xl"> {item[PlotInterface.scholar_vault] ?? "--"} MAG </span> to take owner right of this item.
                        </div>
                        <div className="flex gap-1 justify-center text-center text-lg my-4">
                          <div className="justify-center self-center">Are you sure want to continue</div>
                        </div>
                      </>, 'text', async () => {
                        await takeOwnerRight(item[PlotInterface.id], NFTType.Plot)
                      })
                    }}
                  />
                } else if (item.type === "box") {
                  return <BoxCard key={`box-card-${idx}`} boxInfo={item}
                    assignFn={async () => {
                      await assignItem(id, typeFilter, item.id)
                    }}
                    unassignFn={async () => {
                      await unassignItem(id, typeFilter, item.id)
                    }}
                    cleanFn={() => {
                      setCleanData({
                        type: typeFilter,
                        id: item.id,
                        itemInfo: item
                      })
                    }}
                    assignWaitingFn={async () => {
                      await assignWaitingFn(id, typeFilter, item[PlotInterface.id])
                    }}
                  />
                }
              })}
            </div>
            <div className="w-full self-center">
              {nftTotal > 0 ? <Pagination
                defaultActivePage={nftPage}
                totalPages={Math.ceil(nftTotal / nftLimit)}
                onPageChange={(event: any, pageInfo) => {
                  setNftPage(Number(pageInfo.activePage));
                }}
                firstItem={null}
                lastItem={null}
                className='bg-black-secondary mx-auto text-center self-center' />
                : <div className="h-[200px]">
                  No assets found
                </div>
              }
            </div>
          </>
        }
      </div >

      {cleanData !== null && <ModalClean cleanData={cleanData} setCleanData={setCleanData} onTrigger={async () => {
        await getItems();
      }} />}
    </div>
  );
}

const ModalClean = ({ cleanData, setCleanData, onTrigger }: { cleanData: { type: NFTType, id: number, itemInfo: any }, setCleanData: React.Dispatch<any>, onTrigger: any }) => {

  const [loading, setLoading] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [fee, setFee] = useState(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const getCleanFee = async () => {
      try {
        setLoading(true);
        let { fee } = await api.post('/scholar/get-nft-clean-fee', {
          id: cleanData.id,
          type: cleanData.type
        })
        setFee(fee);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    }
    getCleanFee();
  }, [])

  const clean = async () => {
    try {
      setLoadingConfirm(true)
      let { fee, mongenInfos, soulcoreInfos, plotInfos, customerInfo, boxInfos } = await api.post('/scholar/clean', {
        id: cleanData.id,
        type: cleanData.type
      })
      dispatch(setUserInfo(customerInfo))
      ui.alertSuccess("Clean item successfull", "");
      await onTrigger();
      setCleanData(null);
    } catch (err: any) {
      ui.alertFailed('Failed to clean item', t(err.message))
    } finally {
      setLoadingConfirm(false);
    }
  }

  return (
    <Modal
      size="small"
      open={!!cleanData}
      style={{ width: "unset" }}
    >
      <div
        className="max-w-[564px] w-screenn"
      >
        <div className="w-[564px] bg-black-secondary pb-8">
          <div>
            <div className="text-center text-white text-xl py-6 font-extrabold main-font-bold">
              Clean item
            </div>
            <img src="/assets/images/hr.svg" alt="hr" className="w-full object-cover h-1 mb-9" />
            {cleanData.type === NFTType.Plot &&
              <div className="w-full h-[120px] relative mb-7">
                <PlotThumb plotInfo={cleanData.itemInfo} />
              </div>
            }
            {[NFTType.Mongen, NFTType.SoulCore].includes(cleanData.type) &&
              <div className="w-full h-[120px] relative mb-7">
                <MongenThumb mongenInfo={cleanData.itemInfo} />
              </div>
            }
            <div className="text-center text-white">
              Are you sure you want to use
              <span className="text-yellow font-bold text-lg"> {fee ?? "--"} MSTR </span> to
              clean this item ?
            </div>
            <div className="flex w-[360px] gap-4 mt-9 text-center items-center mx-auto">
              <ArtButton color="red"
                className="py-2.5"
                onClick={() => {
                  setCleanData(null)
                  setFee(null);
                }}
              >
                Cancel
              </ArtButton>
              <ArtButton color="yellow"
                className="py-2.5"
                disabled={fee === null}
                loading={loading}
                onClick={async () => {
                  if (loading) {
                    return;
                  }
                  if (fee === null) {
                    return;
                  }
                  await clean();
                }}
              >
                Confirm
              </ArtButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}