import CustomButton, { ArtButton } from "components/button";
import CustomTable from "components/table";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Header, Input, Modal, Pagination } from "semantic-ui-react";
import { api, ui, utils } from "services";
import AddAccount from "./add-farmer";
import { useTranslation } from "react-i18next";
import { FTLayout } from "components/ft-layout";
import { CustomerInterface } from "interfaces";
import Config from "config";
import { PasswordInput } from "components/password-input";
import { TextInput } from "components/text-input";

export default function Scholar() {
  const nav = useNavigate();
  const { t } = useTranslation()
  const [scholars, setScholars] = useState([]);
  const [createFormPopup, setCreateFormPopup] = useState(false);
  const [pattern, setPattern] = useState("");
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [trigger, setTrigger] = useState(false);
  const [popupChangePassword, openChangePassword] = useState(null);
  const [password, setPassword] = useState("");

  const tableHeader = [
    {
      fieldName: "ID",
      className: "text-yellow underline cursor-pointer",
      fieldKey: CustomerInterface.id,
      render: (children: any, identity: string) => <div onClick={() => {
        nav(`/scholar/detail/${identity}`)
      }}>{children}</div>
    },
    {
      fieldName: "Name",
      className: "text-yellow underline cursor-pointer",
      fieldKey: "identity",
      render: (children: any, identity: string) => <div>{children}</div>
    },
    {
      fieldName: "Today Earned",
      className: "",
      fieldKey: "current",
      render: (children: any) => FTLayout(children)
    },
    {
      fieldName: "Yesterday Earned",
      className: "",
      fieldKey: "prev",
      render: (children: any) => FTLayout(children)
    },
    {
      fieldName: "Average",
      className: "",
      fieldKey: "average",
      render: (children: any) => FTLayout(children)
    },
    {
      fieldName: "Scholar",
      className: "",
      fieldKey: "all",
      render: (children: any) => FTLayout(children["scholar"], children?.[CustomerInterface.sharing_rate])
    },
    {
      fieldName: "Manager",
      className: "",
      fieldKey: "all",
      render: (children: any) => FTLayout(children["manager"], children?.[CustomerInterface.sharing_rate], true)
    },
    {
      fieldName: "Total claimed",
      className: "",
      fieldKey: "total",
      render: (children: any) => FTLayout(children)
    },
    {
      fieldName: "",
      className: ""
    },
  ];

  useEffect(() => {
    api.getScholarsAccount({
      limit,
      offset: (page - 1) * limit,
    }).then(data => {
      setScholars(data?.data ?? [])
      setTotal(data?.total ?? 0)
    })
  }, [page, limit, trigger])

  const onSearch = () => {
    api.getScholarsAccount({
      limit,
      offset: (page - 1) * limit,
      pattern
    }).then(data => {
      setScholars(data?.data ?? [])
      setTotal(data?.total ?? 0)
    });
  }

  const deleteAccount = async (identity: number) => {
    try {
      await api.deleteScholarAccount(identity)
      ui.alertSuccess(`Delete account ${identity} success !`, "");
    } catch (err: any) {
      ui.alertFailed(t(err.message), "");
    }
  }

  const resetPassword = async (identity: number, password: string) => {
    try {
      await api.changeScholarPassword(identity, password)
      ui.alertSuccess(`Change password account ${identity} success !`, "");
    } catch (err: any) {
      ui.alertFailed(t(err.message), "");
    }
  }

  return (
    <div className="main-layout w-full pt-10 px-14 text-white" id="resize-content">
      <div className="w-full" id="resize-content">
        <Header className="text-left flex justify-between">
          <div className="pb-10 text-white text-3xl font-semibold">
            Scholars
          </div>
          <div className="h-[40px] text-white font-semibold flex gap-10">
            <div className="flex custom-input-container rounded-lg">
              <Input
                className="custom-input"
                placeholder="Search by name"
                onChange={(e, data) => {
                  setPattern(data.value);
                }}
              />
              <div className="w-[40px] h-full cursor-pointer"
                onClick={() => onSearch()}
              >
                <img src="/assets/images/search.svg" alt="search" className="w-9 h-9 mt-1" />
              </div>
            </div>

            <CustomButton color="yellow"
              className="px-12 text-[14px]"
              onClick={() => {
                setCreateFormPopup(true);
              }}
            >Add new</CustomButton>
          </div>
        </Header>
        <div className="mt-3 mb-10 bg-black-secondary text-white-light rounded-lg pb-20">
          <div>
            <CustomTable
              className="overflow-y-scroll scholar-table"
              tableHeader={tableHeader}
              tableData={scholars}
              onDelete={deleteAccount}
              onChangePassword={(identity: any) => {
                openChangePassword(identity[CustomerInterface.id])
              }}
              identityField={CustomerInterface.id}
              refetch={trigger}
              setRefetch={setTrigger}
              onEdit={(data: any) => {
                nav(`/scholar/edit/${data[CustomerInterface.id]}`)
              }}
              overrideHeader="bg-gray-header"
            />
            <Pagination
              defaultActivePage={page}
              totalPages={Math.ceil(total / limit)}
              onPageChange={(event: any, pageInfo) => {
                setPage(Number(pageInfo.activePage));
              }}
              firstItem={null}
              lastItem={null}
              className='bg-black-secondary' style={{ float: "right" }}
            />
          </div>
        </div>
        <AddAccount
          open={createFormPopup}
          onClose={() => { setCreateFormPopup(false) }}
          trigger={trigger}
          setTrigger={setTrigger}
        />
        <Modal
          size="small"
          open={popupChangePassword !== null}
          style={{ width: "unset" }}
        >
          <div
            className="max-w-[520px] w-screen"
          >
            <div className="max-w-[520px] w-screen bg-black-secondary pb-8 rounded-xl">
              <div>
                <div className="text-center text-white text-xl py-6 font-extrabold main-font-bold">
                  Change password
                </div>
                <img src="/assets/images/hr.svg" alt="hr" className="w-full object-cover h-1 mb-6" />
                <div className="w-11/12 mx-auto">
                  <PasswordInput
                    submit={() => { }}
                    setErr={() => { }}
                    fieldName="New password"
                    setPassword={setPassword} password={password}
                  />
                </div>
                <div className="flex w-[360px] gap-4 mt-6 text-center items-center mx-auto">
                  <ArtButton color="red"
                    onClick={() => {
                      openChangePassword(null)
                    }}
                  >
                    <div className="text-white font-semibold">
                      Cancel
                    </div>
                  </ArtButton>
                  <ArtButton color="yellow"
                    onClick={async () => {
                      await resetPassword(popupChangePassword, password)
                      openChangePassword(null)
                    }}
                  >
                    <div className="text-white font-semibold">
                      Confirm
                    </div>
                  </ArtButton>
                </div>
              </div >
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
