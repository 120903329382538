import CustomButton from "components/button";
import ArrowLink from "components/links/ArrowLink";
import CustomTable from "components/table";
import Config, { SSO_META } from "config";
import { CustomerInterface } from "interfaces";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "reducer/store";
import { Header, Input, Pagination } from "semantic-ui-react";

/* eslint-disable react-hooks/exhaustive-deps */
export default function ClanMember() {
  const user = useSelector((state: RootState) => state.user);
  const nav = useNavigate();

  const tableHeader = [
    {
      fieldName: "",
      className: "w-[100px]",
      fieldKey: "C",
      render: (children: any, identity: string) => <div>
        <img src="/assets/images/rank/1.svg" alt="rank" />
      </div>
    },
    {
      fieldName: "Player name",
      className: "",
      fieldKey: "C",
      render: (children: any) =>
        <div className="text-lg">
          Clan Name
        </div>
    },
    {
      fieldName: "Friend code",
      className: "",
      fieldKey: "today",
      render: (children: any) => <div>
        1,252
      </div>
    },
    {
      fieldName: "Position",
      className: "",
      fieldKey: "yesterday",
      render: (children: any) => <div>
        900
      </div>
    },
    {
      fieldName: "Level",
      className: "",
      fieldKey: "yesterday",
      render: (children: any) => <div>
        900
      </div>
    },
    {
      fieldName: "Elo",
      className: "",
      fieldKey: "yesterday",
      render: (children: any) => <div>
        900
      </div>
    },
    {
      fieldName: "Time",
      className: "",
      fieldKey: "yesterday",
      render: (children: any) => <div>
        2 days ago
      </div>
    },
    {
      fieldName: "Operation",
      className: "",
      fieldKey: "yesterday",
      render: (children: any) => <div className="flex gap-4">
        <img src="/assets/images/action/up.svg" alt="up" />
        <img src="/assets/images/action/remove.svg" alt="remove" />
      </div>
    },
  ];

  return (
    <div className="main-layout w-full pt-10 px-14 text-white" id="resize-content">
      <div className="w-full" id="resize-content">
        <Header className="text-left">
          <div className="pb-10 text-white text-3xl font-semibold">
            Clan member
          </div>
        </Header>
        {!user?.[CustomerInterface.id] ? <div className="w-3/4 mx-auto mt-20">
          <div className="font-cookie-run text-3xl">
            Clans in Monsterra world!
          </div>
          <div className="mt-8 mb-20 text-lg ">
            <div>Plunge into the battle for resources and territories and receive unique awards.</div>
            <div>Join a clan to discover a new world of battles!</div>
          </div>
          <div className="mt-8 pb-8 border-section text-lg">
            <ArrowLink direction="right" href={SSO_META().DocsLink}>
              Are you worried about security?
            </ArrowLink>
          </div>
          <div className="mt-8 pb-8 border-section text-lg">
            <ArrowLink direction="right" href={SSO_META().DocsLink}>
              Still have concerns? Join our Discord server!
            </ArrowLink>
          </div>
          <div className="mt-8 pb-8 text-lg">
            <ArrowLink direction="right" href={SSO_META().DocsLink}>
              Are you curious about what information we display? Check out some examples.
            </ArrowLink>
          </div>
        </div> :
          <div className="mt-3 mb-10 bg-black-secondary text-white-light rounded-lg pb-20">
            <div>
              <CustomTable
                className="overflow-y-scroll scholar-table"
                tableHeader={tableHeader}
                tableData={[{ "C": "test", "mag": 1, "mstr": 1 }]}
                // refetch={refetch}
                // setRefetch={setRefetch}
                overrideHeader="bg-gray-header"
              />
              <Pagination
                defaultActivePage={1}
                totalPages={1}
                onPageChange={(event: any, pageInfo) => {
                  // setPage(Number(pageInfo.activePage));
                }}
                firstItem={null}
                lastItem={null}
                className='bg-black-secondary' style={{ float: "right" }}
              />
            </div>
          </div>}
      </div >
    </div >
  );
}
