/* eslint-disable react-hooks/exhaustive-deps */
import { ArtButton } from "components/button";
import Selection from "components/select";
import { CustomerInterface, CustomerResource, TransactionType } from "interfaces";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducer/store";
import { setUserInfo } from "reducer/user.slice";
import { Modal } from "semantic-ui-react";
import { api, ui, utils } from "services";

export default function AssignForm({ open, onClose, scholarId }: any) {
  const { t } = useTranslation();
  const [amount, setAmount] = useState(0);
  const userInfo = useSelector((state: RootState) => state.user);
  const [selectedOption, setSelectedOption] = useState(0);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const options = [{
    value: CustomerResource.UNLOCK_MAG,
    name: "MAG",
    icon: '/assets/images/items/mag-icon.svg',
    field: userInfo[CustomerInterface.staked_mag]
  },
  {
    value: CustomerResource.MSTR,
    name: "MSTR",
    icon: '/assets/images/items/mstr-icon.svg',
    field: userInfo[CustomerInterface.mstr]
  },
  {
    value: CustomerResource.Elixir,
    name: "Elixir",
    icon: '/assets/images/items/elixir-icon.webp',
    field: userInfo[CustomerInterface.elixir]
  },
  {
    value: CustomerResource.MutatedGen,
    name: "Mutated Gen",
    icon: '/assets/images/items/mutategen-icon.png',
    field: userInfo[CustomerInterface.mutated_gen]
  },
  {
    value: CustomerResource.NormalStone,
    name: "Normal Rune",
    icon: '/assets/images/items/normal-stone.png',
    field: userInfo[CustomerInterface.nm_st]
  },
  {
    value: CustomerResource.RerollStone,
    name: "Reroll Stone",
    icon: '/assets/images/items/rerolled-stone.png',
    field: userInfo[CustomerInterface.rr_st]
  },
  {
    value: CustomerResource.NormalRuneStone,
    name: "Normal Rune Stone",
    icon: '/assets/images/items/normal-rune.png',
    field: userInfo[CustomerInterface.nmr_st]
  },
  {
    value: CustomerResource.RerollRuneStone,
    name: "Reroll Rune Stone",
    icon: '/assets/images/items/rerolled-rune.png',
    field: userInfo[CustomerInterface.rrr_st]
  },
  ]

  const [selectedOptionData, setOptionData] = useState(options[0])

  useEffect(() => {
    let item = options.find(e => e.value === selectedOption);
    if (item) {
      setOptionData(item);
    }
  }, [selectedOption, userInfo])

  useEffect(() => {
    setSelectedOption(options[0].value);
  }, [open])

  useEffect(() => {
    setAmount(0);
  }, [selectedOption])

  async function onSubmit() {
    if (!amount) { return }
    setLoading(true);
    try {
      let { managerInfo } = await api.assignFt(scholarId, selectedOption, amount);
      dispatch(setUserInfo(managerInfo))
      ui.alertSuccess(`Assign assets to Scholar ${scholarId} success`, "");
    } catch (err: any) {
      ui.alertFailed(t(err.message), "");
    } finally {
      setLoading(false);
      close();
    }
  }

  function close() {
    setAmount(0);
    onClose();
  }

  return (
    <Modal
      size="small"
      open={open}
      style={{ width: "unset" }}
    >
      <div
        className="max-w-[564px] w-screenn"
      >
        <div className="max-w-[564px] w-screen bg-black-secondary pb-8">
          <div>
            <div className="text-center text-white text-xl py-6 font-extrabold main-font-bold">
              Assign Token
            </div>
            <img src="/assets/images/hr.svg" alt="hr" className="w-full object-cover h-1 mb-9" />

            <div className="w-11/12 mx-auto">

              <div className="text-center flex mx-auto justify-center w-full rounded-lg mb-3">
                <div className="relative w-full">
                  <div className="flex absolute inset-y-0 left-0 pl-3 pointer-events-none flex-col">
                    <span className="text-gray text-sm mt-1 mr-2">{t("token")}</span>
                    <div className="flex">
                      <img className="h-6 w-6 mt-1" src={`/assets/images/wallets/metamask.svg`} alt="coin" />
                    </div>
                  </div>
                  <Selection
                    setValue={setSelectedOption}
                    options={options}
                    title="Item resource"
                  />
                </div>
              </div>

              <div className="text-center text-white flex mx-auto justify-center w-full overflow-hidden rounded-lg ">
                <div className="relative w-full">
                  <div className="flex absolute inset-y-0 left-0 pl-3 pointer-events-none flex-col">
                    <span className="text-gray text-sm mt-1">{t("amount")}</span>
                    <img className="h-6 w-6 mt-1" src={selectedOptionData?.icon} alt="coin" />
                  </div>
                  <input
                    type="text"
                    className="text-sm font-semibold block w-full h-[54px] pl-11 pt-5 text-black border-t-3 border-orange"
                    value={amount}
                    onChange={(e: any) => {
                      let value = e.target.value;
                      setAmount(value)
                    }}
                  />
                </div>
                <div
                  className="unselectable bg-orange h-[54px] px-4 text-center pt-4 text-lg font-medium cursor-pointer"
                  style={{
                    borderLeft: "1px solid #7C6F5F",
                  }}
                  color="yellow"
                  onClick={(evt) => {
                    evt.preventDefault();
                    setAmount(selectedOptionData?.field);
                  }}
                >
                  {t("max")}
                </div>
              </div>

              <div className="font-normal text-white-light mt-3">
                {t("available")}: {" "}
                <span>
                  {utils.convertToInternationalCurrencySystem(selectedOptionData?.field)}
                </span>
              </div>
            </div>

            <div className="flex w-[360px] gap-4 mt-8 text-center items-center mx-auto">
              <ArtButton color="red"
                className="py-3"
                disabled={loading}
                onClick={() => {
                  close();
                }}
              >
                {t("cancel")}
              </ArtButton>
              <ArtButton color="yellow"
                className="py-3"
                disabled={!amount || Number(amount) <= 0}
                loading={loading}
                onClick={async () => {
                  await onSubmit();
                }}
              >
                Assign
              </ArtButton>
            </div>
          </div>
        </div>
      </div>
    </Modal >
  );
}
