import { useEffect, useState } from "react";

function formatCode(code) {
  let path = code.split("_");
  let first = path[0];
  let sec = path[1];
  if (sec.length == 2) {
    sec = "0" + sec;
  }
  return first + "_" + sec;
}

export default function Decor({ decoration_list = [], rarity, isDetail = false }) {
  console.log(decoration_list)
  function getDecorImage(deco) {
    if (!deco) {
      return "";
    }
    if (deco.type === 0) {
      return `decor/Background/${deco.decoration_code}.png`;
    }
    if (deco.patterns.length === 1) {
      return `decor/Part/${formatCode(deco.decoration_code)}.png`;
    } else {
      return `decor/Part/${formatCode(deco.decoration_code)}_${Number(deco.active_pattern_idx) + 1
        }.png`;
    }
  }
  function getSize(rarity) {
    if (!rarity) {
      rarity = 0;
    }
    let map = [1, 0, 2, 4, 9];
    return map[rarity];
  }
  const [bg, setBg] = useState("");
  const [parts, setParts] = useState([]);
  useEffect(() => {
    let bg = decoration_list.find((i) => i.type === 0);
    let parts = decoration_list.filter((i) => i.type !== 0);
    setBg(getDecorImage(bg));
    let size = getSize(rarity);
    let tmp = parts.map((p) => {
      let x = 1 + p.x;
      let y = -p.y;
      switch (size) {
        case 1:
          return {
            img: getDecorImage(p),
            x: "50%",
            y: "50%",
            width: "80%",
            height: "80%",
          };
        case 2:
          return {
            img: getDecorImage(p),
            x: `${x * 50 + 25}%`,
            y: `${y * 50 + 50}%`,
            width: "40%",
            height: "40%",
          };
        case 4:
          return {
            img: getDecorImage(p),
            x: `${x * 50 + 25}%`,
            y: `${y * 50 + 25}%`,
            width: "40%",
            height: "40%",
          };
        case 9:
          return {
            img: getDecorImage(p),
            x: `${x * 30 + 50}%`,
            y: `${y * 30 + 15}%`,
            width: "30%",
            height: "30%",
          };
        default:
          return {
          }
      }
    });
    setParts(tmp);
  }, [JSON.stringify(decoration_list)]);
  return (
    <div style={{
      paddingTop: "100%", position: "relative",
    }}
      className={"max-h-full mx-auto -translate-y-10 h-[40px] relative "}
    >
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
        }}
      >
        <img
          src={bg}
          style={{
            left: '50%',
            position: "absolute",
            width: "100%",
            height: "100%",
            objectFit: "contain",
            left: isDetail && 0,
            transform: `${!isDetail && "translateX(-50%)"}  `
          }}
          alt="part"
        />
        {parts.map((p, index) => (
          <img
            key={index}
            src={p.img}
            style={{
              position: "absolute",
              left: p.x,
              top: p.y,
              width: p.width,
              height: p.height,
              transform: "translate(-50%,-50%)",
            }}
            alt="part"
          />
        ))}
      </div>
    </div>
  );
}
