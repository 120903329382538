import CustomButton from "components/button";
import { SSO_META } from "config";
import { CustomerInterface, MongenRace, PlotInterface, PlotType, Rarity, ScholarItemStatus, ScholarItemStatusDisplay, } from "interfaces";
import { useState } from "react";
import { utils } from "services";
import Decor from "services/decor";
import { useCountdown } from "services/useCountdown";

export const PlotCard = ({
  plotInfo,
  userInfo,
  assignFn,
  unassignFn,
  cleanFn,
  exchangeFn,
  assignWaitingFn,
  getOwnerRight
}: {
  plotInfo: any,
  userInfo: any,
  assignFn: any,
  unassignFn: any,
  cleanFn: any,
  exchangeFn: any
  assignWaitingFn: any,
  getOwnerRight: any
}) => {
  const status = plotInfo.scholarItemStatus;
  const [loadingUnassign, setLoadingUnassign] = useState(false);
  const [loadingAssign, setLoadingAssign] = useState(false);

  const is_lock = plotInfo[PlotInterface.is_lock];
  const freeze_until = plotInfo[PlotInterface.freeze_until];

  const [days, hours, minutes, seconds, isFinish] = useCountdown(freeze_until)

  return (
    <div className="bg-black-secondary nft-card">
      <div className="flex justify-between pt-1 pb-3">
        <div>Plot #{plotInfo[PlotInterface.id]}</div>
        <div>#{plotInfo[PlotInterface.id]}</div>
      </div>
      <div className="bg-[#141416] w-full h-3/4 relative">
        <div className="nft-image">
          <img src={utils.getRarityIcon(plotInfo[PlotInterface.rarity] ?? Rarity.Common)}
            alt="rarity"
            className="absolute right-2 top-2 w-8 h-8" />
          {plotInfo[PlotInterface.plot_type] === PlotType.Pasture &&
            <img src={`/assets/images/race/${MongenRace[plotInfo[PlotInterface.plot_race] ?? MongenRace.Beast].toString().toLowerCase()}.png`}
              alt="tectos"
              className="absolute right-2 top-12 w-8 h-8"
            />}
          <div className="absolute top-2 left-2 font-semibold text-lg">
            {plotInfo.customerInfo?.[CustomerInterface.name] ?? ""}
          </div>

          <div className="absolute top-10 left-2">
            {ScholarItemStatusDisplay[status]}
          </div>
          <div className="w-full h-3/5 translate-y-1/4 absolute">
            <PlotThumb plotInfo={plotInfo} />
          </div>
          <div className="text-white absolute bottom-2 justify-center mx-auto w-full text-sm flex flex-wrap gap-4">
            <div className="flex gap-2">
              <span>{plotInfo.stats?.hp}</span>
              <img src="/assets/images/stats/hp.svg" alt="hp" className="-translate-y-[4px]" />
            </div>
          </div>
        </div>
      </div>
      {isFinish ? <div className="flex justify-between pt-1 pb-3 self-center gap-4 mt-2">
        {![ScholarItemStatus.Waiting].includes(status) && <CustomButton color="blue"
          onClick={() => {
            window.open(`${SSO_META().MarketLink}/#/p2p-market?tab=Landplots`, "_blank")
          }}
        >
          Find similar
        </CustomButton>}
        {status === ScholarItemStatus.Available && <CustomButton color="orange"
          loading={loadingAssign}
          onClick={async () => {
            setLoadingAssign(true);
            plotInfo[PlotInterface.is_free] ? await cleanFn() : await assignFn()
            setLoadingAssign(false);
          }}
        >
          {plotInfo[PlotInterface.is_free] ? "Clean" : "Assign"}
        </CustomButton>}
        {[ScholarItemStatus.Waiting].includes(status) && <CustomButton color="orange"
          loading={loadingAssign}
          onClick={async () => {
            setLoadingAssign(true);
            await assignWaitingFn();
            setLoadingAssign(false);
          }}
        >
          {"Assign"}
        </CustomButton>}
        {[ScholarItemStatus.Lending, ScholarItemStatus.Waiting].includes(status) && plotInfo[PlotInterface.is_free] && <CustomButton
          color="red"
          loading={loadingUnassign}
          onClick={cleanFn}
        >
          Clean
        </CustomButton>}
        {[ScholarItemStatus.Lending, ScholarItemStatus.Waiting].includes(status) && !plotInfo[PlotInterface.is_free] && <CustomButton
          color="red"
          loading={loadingUnassign}
          onClick={plotInfo[PlotInterface.scholar_vault] ? getOwnerRight : async () => {
            setLoadingUnassign(true);
            await unassignFn();
            setLoadingUnassign(false);
          }}
        >
          {plotInfo[PlotInterface.scholar_vault] ? "Take Owner Right" : "Unassign"}
        </CustomButton>}
        {[ScholarItemStatus.Waiting].includes(status) && !plotInfo[PlotInterface.is_high_fee] && !plotInfo[PlotInterface.is_deassigning] && <CustomButton
          color="blue"
          loading={loadingUnassign}
          onClick={async () => {
            setLoadingUnassign(true);
            await exchangeFn()
            setLoadingUnassign(false);
          }}
        >
          Exchange
        </CustomButton>}
      </div> : <div className="flex">
        <div className="text-lg text-center w-full self-center justify-center py-4">
          This item is locked</div>
      </div>
      }
    </div>
  )
}

export const PlotThumb = ({ plotInfo }: { plotInfo: any }) => {
  return (
    <>
      {
        plotInfo[PlotInterface.plot_type] !== PlotType.Decor && <img src={utils.getPlotIcon({
          race: plotInfo[PlotInterface.plot_race] ?? 0,
          rarity: plotInfo[PlotInterface.rarity] ?? 0,
          plot_type: plotInfo[PlotInterface.plot_type] ?? 0,
          sub_plot_type: plotInfo[PlotInterface.sub_plot_type] ?? 0
        })}
          alt={plotInfo[PlotInterface.plot_type] ?? 0}
          className="mx-auto h-full" />
      }
      {
        plotInfo[PlotInterface.plot_type] === PlotType.Decor && <div className="w-full relative h-full">
          <div className="w-1/2 self-center justify-center mx-auto translate-y-1/4 max-w-[140px]">
            <Decor rarity={plotInfo[PlotInterface.rarity] ?? 0}
              decoration_list={plotInfo[PlotInterface.decoration_list]}
              isDetail={true}
            />
          </div>
        </div>
      }
    </>
  )
}
