import { ArtButton } from "components/button";
import { TextInput } from "components/text-input";
import { LoginType } from "interfaces";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Checkbox,
    Modal,
    Select,
} from "semantic-ui-react";
import { api, ui } from "services";
import { PasswordInput } from "../../components/password-input";

export default function AddAccount({ open, onClose, trigger, setTrigger }: any) {
    const [password, setPassword] = useState("");
    const [username, setUsername] = useState("");
    const [walletAddress, setWalletAddress] = useState("");
    const [sharingRate, setSharingRate] = useState({
        "mstrRate": 0,
        "magRate": 0,
        "elixirRate": 0,
        "mutategenRate": 0,
        "autoAssignNft": false
    })
    const [expectAmount, setExpectAmount] = useState({
        "expectEarningMstr": 0,
        "expectEarningMag": 0,
        "expectEarningElixir": 0,
        "expectEarningMutategen": 0,
    })
    const [loading, setLoading] = useState(false);
    const [textErr, setTextErr] = useState("");
    const [pwErr, setPwErr] = useState("");
    const [scholarLoginType, setScholarLoginType] = useState(LoginType.BSC);
    const { t } = useTranslation();
    function close() {
        resetState();
        onClose();
    }
    const onSubmit = () => {
        setLoading(true);
        api.createScholarAccount(
            username,
            password,
            walletAddress,
            sharingRate,
            expectAmount,
            scholarLoginType
        ).then(data => {
            close();
            setLoading(false);
            setTrigger(!trigger);
        }).catch(err => {
            setLoading(false);
            ui.alertFailed("", t(err.message))
        })
    }
    const resetState = () => {
        setPassword("");
        setUsername("");
        setWalletAddress("");
        setSharingRate({
            "mstrRate": 0,
            "magRate": 0,
            "elixirRate": 0,
            "mutategenRate": 0,
            "autoAssignNft": false
        })
        setExpectAmount({
            "expectEarningMstr": 0,
            "expectEarningMag": 0,
            "expectEarningElixir": 0,
            "expectEarningMutategen": 0,
        })
    }
    return (
        <Modal
            size="small"
            open={open}
            style={{ width: "unset" }}
        >
            <div
                className="max-w-[720px] w-screen"
            >
                <div className="max-w-[720px] w-screen bg-black-secondary pb-8 rounded-xl">
                    <div>
                        <div className="text-center text-white text-xl py-6 font-extrabold main-font-bold">
                            Add new scholar
                        </div>
                        <img src="/assets/images/hr.svg" alt="hr" className="w-full object-cover h-1 mb-6" />

                        <div className="w-11/12 mx-auto">
                            <TextInput
                                type="username"
                                submit={onSubmit}
                                setErr={setTextErr}
                                fieldName="Username" value={username} setValue={setUsername}
                            />
                            <PasswordInput
                                submit={onSubmit}
                                setErr={setPwErr}
                                fieldName="New password" setPassword={setPassword} password={password}
                            />
                            <div className="flex gap-3">
                                <TextInput
                                    className="w-full"
                                    submit={onSubmit}
                                    fieldName="Scholar Wallet address" value={walletAddress} setValue={setWalletAddress}
                                />
                                <Select
                                    className="select-chain"
                                    defaultValue={LoginType.BSC} options={[
                                        {
                                            key: `chain-${LoginType.BSC}`, value: LoginType.BSC, text: <div
                                                className="flex gap-2"
                                            >
                                                <img src="/assets/images/wallets/bsc.png" alt="bsc" className="w-6 h-6 self-center justify-center" />
                                                <div className="self-center font-bold">BSC</div>
                                            </div>
                                        },
                                        {
                                            key: `chain-${LoginType.Avalanche}`, value: LoginType.Avalanche, text: <div
                                                className="flex gap-2"
                                            >
                                                <img src="/assets/images/wallets/avalanche.png" alt="avalanche" className="w-6 h-6 self-center justify-center" />
                                                <div className="self-center font-bold">Avax</div>
                                            </div>
                                        },
                                        {
                                            key: `chain-${LoginType.Okex}`, value: LoginType.Okex, text: <div
                                                className="flex gap-2"
                                            >
                                                <img src="/assets/images/wallets/okex.png" alt="okex" className="w-6 h-6 self-center justify-center" />
                                                <div className="self-center font-bold">Okex</div>
                                            </div>
                                        },
                                    ]}
                                    onChange={(_, value: any) => {
                                        setScholarLoginType(value.value);
                                    }}
                                />
                            </div>
                            <div className="w-full grid grid-cols-2 gap-x-4">
                                <TextInput
                                    type="number"
                                    step={0.1}
                                    min={0}
                                    submit={onSubmit}
                                    defaultValue={0}
                                    fieldName="Expect MSTR earning per day"
                                    value={expectAmount.expectEarningMstr}
                                    setValue={(value: any) => {
                                        setExpectAmount({
                                            ...expectAmount,
                                            expectEarningMstr: value,
                                        });
                                    }}
                                />
                                <TextInput
                                    type="number"
                                    step={0.1}
                                    min={0}
                                    submit={onSubmit}
                                    defaultValue={0}
                                    fieldName="Expect MAG earning per day"
                                    value={expectAmount.expectEarningMag}
                                    setValue={(value: any) => {
                                        setExpectAmount({
                                            ...expectAmount,
                                            expectEarningMag: value,
                                        });
                                    }}
                                />
                                <TextInput
                                    type="number"
                                    step={0.1}
                                    min={0}
                                    submit={onSubmit}
                                    defaultValue={0}
                                    fieldName="Expect Mutate Gen earning per day"
                                    value={expectAmount.expectEarningMutategen}
                                    setValue={(value: any) => {
                                        setExpectAmount({
                                            ...expectAmount,
                                            expectEarningMutategen: value,
                                        });
                                    }}
                                />
                                <TextInput
                                    type="number"
                                    step={0.1}
                                    min={0}
                                    submit={onSubmit}
                                    defaultValue={0}
                                    fieldName="Expect Elixir earning per day"
                                    value={expectAmount.expectEarningElixir}
                                    setValue={(value: any) => {
                                        setExpectAmount({
                                            ...expectAmount,
                                            expectEarningElixir: value,
                                        });
                                    }}
                                />
                                <TextInput
                                    type="number"
                                    step={0.1}
                                    min={0}
                                    max={100}
                                    submit={onSubmit}
                                    fieldName="Scholar MSTR sharing rate (%)"
                                    value={sharingRate.mstrRate}
                                    setValue={(value: any) => {
                                        setSharingRate({
                                            ...sharingRate,
                                            mstrRate: value,
                                        });
                                    }}
                                />
                                <TextInput
                                    type="number"
                                    step={0.1}
                                    min={0}
                                    max={100}
                                    submit={onSubmit}
                                    defaultValue={0}
                                    fieldName="Scholar MAG sharing rate (%)"
                                    value={sharingRate.magRate}
                                    setValue={(value: any) => {
                                        setSharingRate({
                                            ...sharingRate,
                                            magRate: value,
                                        });
                                    }}
                                />
                                <TextInput
                                    type="number"
                                    step={0.1}
                                    min={0}
                                    max={100}
                                    submit={onSubmit}
                                    fieldName="Scholar Mutate Gen sharing rate (%)"
                                    value={sharingRate.mutategenRate}
                                    setValue={(value: any) => {
                                        setSharingRate({
                                            ...sharingRate,
                                            mutategenRate: value,
                                        });
                                    }}
                                />
                                <TextInput
                                    type="number"
                                    step={0.1}
                                    min={0}
                                    max={100}
                                    submit={onSubmit}
                                    fieldName="Scholar Elixir sharing rate (%)"
                                    value={sharingRate.elixirRate}
                                    setValue={(value: any) => {
                                        setSharingRate({
                                            ...sharingRate,
                                            elixirRate: value,
                                        });
                                    }}
                                />

                                <Checkbox
                                    className="col-span-2"
                                    toggle
                                    label="Auto assign new NFTs for scholar"
                                    defaultChecked={sharingRate.autoAssignNft}
                                    onChange={(e, data) => {
                                        setSharingRate({ ...sharingRate, autoAssignNft: !sharingRate.autoAssignNft });
                                    }}
                                />
                            </div>
                        </div>

                        <div className={`flex w-[360px] gap-4 mt-8 text-center items-center mx-auto`}>
                            <ArtButton color="red"
                                onClick={() => {
                                    close();
                                    resetState();
                                }}
                            >
                                <div className="text-white font-semibold">
                                    Cancel
                                </div>
                            </ArtButton>
                            <ArtButton color="yellow"
                                onClick={onSubmit}
                                disabled={!!textErr || !!pwErr}
                                loading={loading}
                            >
                                <div className="text-white font-semibold">
                                    Save
                                </div>
                            </ArtButton>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
