import CustomButton from "components/button";
import { SSO_META } from "config";
import { CustomerInterface, ScholarItemStatus, ScholarItemStatusDisplay, } from "interfaces";
import { useState } from "react";

export const BoxCard = ({
  boxInfo,
  assignFn,
  unassignFn,
  cleanFn,
  assignWaitingFn
}: {
  boxInfo: any,
  assignFn: any,
  unassignFn: any,
  cleanFn: any,
  assignWaitingFn: any
}) => {
  const status = boxInfo.scholarItemStatus;
  const [loadingUnassign, setLoadingUnassign] = useState(false);
  const [loadingAssign, setLoadingAssign] = useState(false);

  return (
    <div className="bg-black-secondary nft-card">
      <div className="flex justify-between pt-1 pb-3">
        <div>Box #{boxInfo.id}</div>
        <div>#{boxInfo.id}</div>
      </div>
      <div className="bg-[#141416] w-full h-3/4 relative">
        <div className="nft-image">
          <div className="absolute top-2 left-2 font-semibold text-lg">
            {boxInfo.customerInfo?.[CustomerInterface.name] ?? ""}
          </div>
          <div className="absolute top-10 left-2">
            {ScholarItemStatusDisplay[status]}
          </div>
          <div className="w-full h-1/2 translate-y-1/2 absolute">
            <BoxThumb boxInfo={boxInfo} />
          </div>
        </div>
      </div>
      <div className="flex justify-between pt-1 pb-3 self-center gap-4 mt-2">
        {![ScholarItemStatus.Waiting].includes(status) && <CustomButton color="blue"
          onClick={() => {
            window.open(`${SSO_META().MarketLink}/#/p2p-market?tab=Boxes`, "_blank")
          }}
        >
          Find similar
        </CustomButton>}
        {status === ScholarItemStatus.Available && <CustomButton color="orange"
          loading={loadingAssign}
          onClick={async () => {
            if (!boxInfo.is_clean && boxInfo.is_cleanable) {
              await cleanFn()
            } else {
              await assignFn()
            }
          }}
        >
          {!boxInfo.is_clean && boxInfo.is_cleanable ? "Clean" : "Assign"}
        </CustomButton>}
        {[ScholarItemStatus.Waiting].includes(status) && <CustomButton color="orange"
          loading={loadingAssign}
          onClick={async () => {
            setLoadingAssign(true);
            await assignWaitingFn();
            setLoadingAssign(false);
          }}
        >
          {"Assign"}
        </CustomButton>}
        {[ScholarItemStatus.Lending, ScholarItemStatus.Waiting].includes(status) && <CustomButton
          color="red"
          loading={loadingUnassign}
          onClick={!boxInfo.is_clean && boxInfo.is_cleanable ? cleanFn : async () => {
            setLoadingUnassign(true);
            await unassignFn();
            setLoadingUnassign(false);
          }}
        >
          {!boxInfo.is_clean && boxInfo.is_cleanable ? "Clean" : "Unassign"}
        </CustomButton>}
      </div>
    </div>
  )
}

export const BoxThumb = ({ boxInfo }: { boxInfo: any }) => {
  let src = boxInfo.image?.replace('https://monsterra-images.s3.ap-southeast-1.amazonaws.com', '/assets/images')
  return (
    <img
      src={src}
      alt="box"
      className="mx-auto h-full"
    />
  )
}
