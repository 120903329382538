import ArrowLink from "components/links/ArrowLink";
import Config, { SSO_META } from "config";
import { CustomerInterface } from "interfaces";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "reducer/store";
import { Card, Header } from "semantic-ui-react";
import { api } from "services";

/* eslint-disable react-hooks/exhaustive-deps */
export default function ScholarDetail() {
  const user = useSelector((state: RootState) => state.user);
  const { id } = useParams();
  const nav = useNavigate();
  const [data, setData] = useState(null);

  const getScholarDetail = async (id: string) => {
    try {
      let data = await api.getScholarDetail(id);
      setData(data);
    } catch (err) {
      console.log({ err })
    }
  }

  useEffect(() => {
    if (id) {
      getScholarDetail(id);
    }
  }, [id])

  return (
    <div className="main-layout w-full pt-10 px-14 text-white" id="resize-content">
      <div className="w-full" id="resize-content">
        <Header className="text-left border-section"
          onClick={() => nav("/scholar/detail")}
        >
          <div className="pb-10 text-white text-3xl font-semibold flex gap-4 cursor-pointer">
            <img src="/assets/images/arrow-left.svg" alt="arrow-left" />
            <span>
              Scholar account {id}
            </span>
          </div>
        </Header>
        {!user?.[CustomerInterface.id] ? <div className="w-3/4 mx-auto mt-20">
          <div className="font-cookie-run text-3xl">
            No accounts to track
          </div>
          <div className="mt-8 mb-20 text-lg ">
            Sign in your public Wallet address to start tracking your account. We will track all of your Assets, daily account statistics, manager and scholar splits, and much more!
          </div>
          <div className="mt-8 pb-8 border-section text-lg">
            <ArrowLink direction="right" href={SSO_META().DocsLink}>
              Are you worried about security?
            </ArrowLink>
          </div>
          <div className="mt-8 pb-8 border-section text-lg">
            <ArrowLink direction="right" href={SSO_META().DocsLink}>
              Still have concerns? Join our Discord server!
            </ArrowLink>
          </div>
          <div className="mt-8 pb-8 text-lg">
            <ArrowLink direction="right" href={SSO_META().DocsLink}>
              Are you curious about what information we display? Check out some examples.
            </ArrowLink>
          </div>
        </div> : <div className="grid grid-cols-3 gap-10 mt-10">
          <div className="col-span-2">
            <div>
              <div className="">
                <img src="/assets/images/items/mstr-icon.svg" alt="mstr" className="inline-block mr-2 h-8 w-8" />
                <span className="text-lg">
                  MSTR
                </span>
              </div>

              <Card
                fluid
                style={{ background: "transparent", boxShadow: "none", marginBottom: "0" }}
                description={
                  <div className="flex flex-col gap-4">
                    <div className="flex gap-6 flex-row ">
                      <div className="w-full bg-black-secondary rounded-xl">
                        <div className="grid grid-cols-3">
                          <div className="card-header text-white-light bg-gray py-3 px-6 rounded-tl-xl">
                          </div>
                          <div className="card-header text-white-light bg-gray py-3 px-6">
                            Earn
                          </div>
                          <div className="card-header text-white-light bg-gray py-3 px-6 rounded-tr-xl">
                            Spend
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              />
              <div className="bg-black-secondary col-span-2 rounded-b-lg">
                <div className="grid grid-cols-3 pt-6">
                  <div className="rounded-md pt-1 mb-4 text-center text-md w-[54px] ml-6">
                    [Yesterday]
                  </div>
                  <div className="pt-1 mb-4 text-center text-lg w-[54px] mx-3 text-green-c font-bold">
                    {data?.yesterday.mstr ?? 0}
                  </div>
                  <div className="pt-1 mb-4 text-center text-lg w-[54px] mx-4 text-red-c font-bold">
                    {data?.yesterday.spendMstr ?? 0}
                  </div>
                </div>
                <div className="grid grid-cols-3 pt-6">
                  <div className="rounded-md pt-1 mb-4 text-center text-md w-[54px] ml-6">
                    [Today]
                  </div>
                  <div className="pt-1 mb-4 text-center text-lg w-[54px] mx-3 text-green-c font-bold">
                    {data?.today.mstr ?? 0}
                  </div>
                  <div className="pt-1 mb-4 text-center text-lg w-[54px] mx-4 text-red-c font-bold">
                    {data?.today.spendMstr ?? 0}
                  </div>
                </div>
                <div className="grid grid-cols-3 pt-6">
                  <div className="custom-btn-color-purple-red rounded-md pt-1 mb-4 text-center text-md w-[54px] ml-6">
                    Total
                  </div>
                  <div className="pt-1 mb-4 text-center text-lg w-[54px] mx-3 text-green-c font-bold">
                    {data?.total.mstr ?? 0}
                  </div>
                  <div className="pt-1 mb-4 text-center text-lg w-[54px] mx-4 text-red-c font-bold">
                    {data?.total.spendMstr ?? 0}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-10">
              <div className="">
                <img src="/assets/images/items/mag-icon.svg" alt="mag" className="inline-block mr-2 h-8 w-8" />
                <span className="text-lg">
                  MAG
                </span>
              </div>
              <Card
                fluid
                style={{ background: "transparent", boxShadow: "none", marginBottom: "0" }}
                description={
                  <div className="flex flex-col gap-4">
                    <div className="flex gap-6 flex-row ">
                      <div className="w-full bg-black-secondary rounded-xl">
                        <div className="grid grid-cols-3">
                          <div className="card-header text-white-light bg-gray py-3 px-6 rounded-tl-xl">
                          </div>
                          <div className="card-header text-white-light bg-gray py-3 px-6">
                            Earn
                          </div>
                          <div className="card-header text-white-light bg-gray py-3 px-6 rounded-tr-xl">
                            Spend
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              />
              <div className="bg-black-secondary col-span-2 rounded-b-lg">
                <div className="grid grid-cols-3 pt-6">
                  <div className="rounded-md pt-1 mb-4 text-center text-md w-[54px] ml-6">
                    [Yesterday]
                  </div>
                  <div className="pt-1 mb-4 text-center text-lg w-[54px] mx-3 text-green-c font-bold">
                    {data?.yesterday.mag ?? 0}
                  </div>
                  <div className="pt-1 mb-4 text-center text-lg w-[54px] mx-4 text-red-c font-bold">
                    {data?.yesterday.spendMag ?? 0}
                  </div>
                </div>
                <div className="grid grid-cols-3 pt-6">
                  <div className="rounded-md pt-1 mb-4 text-center text-md w-[54px] ml-6">
                    [Today]
                  </div>
                  <div className="pt-1 mb-4 text-center text-lg w-[54px] mx-3 text-green-c font-bold">
                    {data?.today.mag ?? 0}
                  </div>
                  <div className="pt-1 mb-4 text-center text-lg w-[54px] mx-4 text-red-c font-bold">
                    {data?.today.spendMag ?? 0}
                  </div>
                </div>
                <div className="grid grid-cols-3 pt-6">
                  <div className="custom-btn-color-purple-red rounded-md pt-1 mb-4 text-center text-md w-[54px] ml-6">
                    Total
                  </div>
                  <div className="pt-1 mb-4 text-center text-lg w-[54px] mx-3 text-green-c font-bold">
                    {data?.total.mag ?? 0}
                  </div>
                  <div className="pt-1 mb-4 text-center text-lg w-[54px] mx-4 text-red-c font-bold">
                    {data?.total.spendMag ?? 0}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-10">
              <div className="">
                <img src="/assets/images/items/evo-item-icon.svg" alt="evo" className="inline-block mr-2 h-8 w-8" />
                <span className="text-lg">
                  Evo pts.
                </span>
              </div>
              <Card
                fluid
                style={{ background: "transparent", boxShadow: "none", marginBottom: "0" }}
                description={
                  <div className="flex flex-col gap-4">
                    <div className="flex gap-6 flex-row ">
                      <div className="w-full bg-black-secondary rounded-xl">
                        <div className="grid grid-cols-3">
                          <div className="card-header text-white-light bg-gray py-3 px-6 rounded-tl-xl">
                          </div>
                          <div className="card-header text-white-light bg-gray py-3 px-6">
                            Earn
                          </div>
                          <div className="card-header text-white-light bg-gray py-3 px-6 rounded-tr-xl">
                            Spend
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              />
              <div className="bg-black-secondary col-span-2 rounded-b-lg">
                <div className="grid grid-cols-3 pt-6">
                  <div className="rounded-md pt-1 mb-4 text-center text-md w-[54px] ml-6">
                    [Yesterday]
                  </div>
                  <div className="pt-1 mb-4 text-center text-lg w-[54px] mx-3 text-green-c font-bold">
                    {data?.yesterday.evol ?? 0}
                  </div>
                  <div className="pt-1 mb-4 text-center text-lg w-[54px] mx-4 text-red-c font-bold">
                    {data?.yesterday.spendEvol ?? 0}
                  </div>
                </div>
                <div className="grid grid-cols-3 pt-6">
                  <div className="rounded-md pt-1 mb-4 text-center text-md w-[54px] ml-6">
                    [Today]
                  </div>
                  <div className="pt-1 mb-4 text-center text-lg w-[54px] mx-3 text-green-c font-bold">
                    {data?.today.evol ?? 0}
                  </div>
                  <div className="pt-1 mb-4 text-center text-lg w-[54px] mx-4 text-red-c font-bold">
                    {data?.today.spendEvol ?? 0}
                  </div>
                </div>
                <div className="grid grid-cols-3 pt-6">
                  <div className="custom-btn-color-purple-red rounded-md pt-1 mb-4 text-center text-md w-[54px] ml-6">
                    Total
                  </div>
                  <div className="pt-1 mb-4 text-center text-lg w-[54px] mx-3 text-green-c font-bold">
                    {data?.total.evol ?? 0}
                  </div>
                  <div className="pt-1 mb-4 text-center text-lg w-[54px] mx-4 text-red-c font-bold">
                    {data?.total.spendEvol ?? 0}
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="bg-black-secondary rounded-lg p-6 mt-12">
            <div className="">
              <img src="/assets/images/items/soulcore-icon.svg" alt="mag" className="inline-block mr-2 w-7 h-7" />
              Soulcore
            </div>
            <div className="flex justify-between py-3">
              <div className="custom-btn-color-purple-red rounded-md pr-3 pl-3 pt-1 text-center text-sm mr-3">
                Total
              </div>
              <div className="text-bold text-lg">0.00</div>
            </div>
            <div className="br" />
            <div className="flex justify-between py-3">
              <div>[Today]</div>
              <div className="text-bold text-lg">1</div>
            </div>
            <div className="br" />
            <div className="flex justify-between py-3">
              <div>[Yesterday]</div>
              <div className="text-bold text-lg">1</div>
            </div>
            <div className="br-2 -mx-6 mt-2 mb-5" />

            <div className="">
              <img src="/assets/images/items/mongen-icon.svg" alt="mag" className="inline-block mr-2 w-7 h-7" />
              Mongens
            </div>
            <div className="flex justify-between py-3">
              <div className="custom-btn-color-purple-red rounded-md pr-3 pl-3 pt-1 text-center text-sm mr-3">
                Total
              </div>
              <div className="text-bold text-lg">0.00</div>
            </div>
            <div className="br" />
            <div className="flex justify-between py-3">
              <div>[Today]</div>
              <div className="text-bold text-lg">1</div>
            </div>
            <div className="br" />
            <div className="flex justify-between py-3">
              <div>[Yesterday]</div>
              <div className="text-bold text-lg">1</div>
            </div>
            <div className="br-2 -mx-6 mt-2 mb-5" />

            <div className="">
              <img src="/assets/images/items/plot-icon.svg" alt="mag" className="inline-block mr-2 w-7 h-7" />
              Plots
            </div>
            <div className="flex justify-between py-3">
              <div className="custom-btn-color-purple-red rounded-md pr-3 pl-3 pt-1 text-center text-sm mr-3">
                Total
              </div>
              <div className="text-bold text-lg">0.00</div>
            </div>
            <div className="br" />
            <div className="flex justify-between py-3">
              <div>[Today]</div>
              <div className="text-bold text-lg">1</div>
            </div>
            <div className="br" />
            <div className="flex justify-between py-3">
              <div>[Yesterday]</div>
              <div className="text-bold text-lg">1</div>
            </div>
            <div className="br-2 -mx-6 mt-2 mb-5" />

            <div className="mt-4">
              <img src="/assets/images/items/evo-item-icon.svg" alt="evo" className="inline-block mr-2 w-7 h-7" />
              EVO pts
            </div>
            <div className="flex justify-between py-3">
              <div className="custom-btn-color-purple-red rounded-md pr-3 pl-3 pt-1 text-center text-sm mr-3">
                Total
              </div>
              <div className="text-bold text-lg">0.00</div>
            </div>
            <div className="br" />
            <div className="flex justify-between py-3">
              <div>[Today]</div>
              <div className="text-bold text-lg">1</div>
            </div>
            <div className="br" />
            <div className="flex justify-between py-3">
              <div>[Yesterday]</div>
              <div className="text-bold text-lg">1</div>
            </div>
          </div>
        </div>}
      </div >
    </div >
  );
}
