import clsx from 'clsx';
import * as React from 'react';
import CustomLink from './CustomLink';
import { UnstyledLinkProps } from './UnstyledLink';

type ArrowLinkProps<C extends React.ElementType> = {
  as?: C;
  direction?: 'left' | 'right';
} & UnstyledLinkProps &
  React.ComponentProps<C>;

export default function ArrowLink<C extends React.ElementType>({
  children,
  className,
  direction = 'right',
  as,
  ...rest
}: ArrowLinkProps<C>) {
  const Component = as || CustomLink;

  return (
    <Component
      {...rest}
      className={clsx(
        className,
        'gap-[0.25em] group',
        direction === 'left' && 'flex-row-reverse',
        'text-white'
      )}
    >
      <span className="text-white font-normal group-hover:font-bold">{children}</span>
      <img src="/assets/images/icons/arrow-right-icon.svg" alt="arrow-right"
        className={clsx(
          'relative',
          'duration-200 transition-transform',
          direction === 'left' ? 'motion-safe:translate-x-0' : 'rotate-180',
          'group-hover:translate-x-2'
        )}
      />
    </Component>
  );
}
